import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './FormDropdownList.css';
import FileItem from '../models/FileItem';
import { Forms } from '../models/Forms';
import { Categories } from '../models/Categories';
import { EdobForms } from '../models/EdobForms';

  type FormPropsType = {
    defaultValue: string | null;
    id: string;
    type: string;
    onChangedForm: (value: string, item: FileItem) => void;
    currentItem: FileItem;
    options: string[];
    placeholder: string;
    wisbForms: (value: string) => Forms | null;
    edobForms: (value: string) => EdobForms | null;
    categories: (value: string) => Categories | null;
    resetSubFormsHandler: (value: string) => void;
    resetSubForms: string;
  }
  const FormDropdownList: React.FC<FormPropsType> = (props) => {
    const { i18n } = useTranslation();
    let allOptions = useRef([] as HTMLElement[]);
    let filteredOptions = useRef([] as HTMLElement[]);
    let theFirstOption = useRef(null as HTMLElement | null);
    let theLastOption = useRef(null as HTMLElement | null);
    let filter = useRef('');
    let comboboxHasVisualFocus = useRef(false);
    let listboxHasVisualFocus = useRef(false);
    let hasFormsHover = useRef(false);
    let isBoth = true;
    let option = useRef(null as HTMLElement | null);
    let isOpened = useRef(false);
    const groupClassName = useRef("group");
    const [focusName, setFocusName] = useState("group");
    let listClicked = useRef(false);
    let enterClicked = useRef(false);
    const currentCategories = useRef('');
    const currentValue = useRef('');
    const currentId = useRef('');
    const swithced = useRef(false);
    const resetIndex = useRef('');
    const [currentFormValue, setCurrentFormValue] = useState(props.defaultValue);

    const isIOS = () => {
        const iosQuirkPresent = () => {
            const audio = new Audio();
            audio.volume = 0.5;
            return audio.volume === 1;   // volume cannot be changed from "1" on iOS 12 and below
        };
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const isAppleDevice = navigator.userAgent.includes('Macintosh');
        const isTouchScreen = navigator.maxTouchPoints >= 1;   // true for iOS 13 (and hopefully beyond)

        return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
    };

    const valideteInput = useCallback((value: string | null) => {
        if (value === '') {
            return true;
        }
        const result = allOptions.current.filter((option) => {
            return option.innerHTML === value?.replaceAll('’', '\'');
        });
        if (result.length === 0) {
            return false;
        }
        return true;
    }, []);

    const filterOptions = useCallback(() => {
        let optionTemp = null;
        let currentOption = option.current;
        let filterStr = filter.current.trim().toLowerCase().replaceAll('’', '\'');
        const listboxNode = document.getElementById(props.id + '-listbox');

        filteredOptions.current = [];
        if (listboxNode) {
            listboxNode.innerHTML = '';
        }

        for (let i = 0; i < allOptions.current.length; i++) {
            optionTemp = allOptions.current[i];
            if (
                filterStr?.length === 0 ||
                getLowercaseContent(optionTemp)?.indexOf(filterStr!)! >= 0
            ) {
                filteredOptions.current.push(optionTemp);
                listboxNode?.appendChild(optionTemp);
            }
        }

        // Use populated options array to initialize firstOption and lastOption.
        var numItems = filteredOptions.current.length;
        if (numItems > 0) {
            theFirstOption.current = filteredOptions.current[0];
            theLastOption.current = filteredOptions.current[numItems - 1];

            if (currentOption && filteredOptions.current.indexOf(currentOption) >= 0) {
                optionTemp = currentOption;
            } else {
                optionTemp = theFirstOption.current;
            }
        } else {
            theFirstOption.current = null;
            optionTemp = null;
            theLastOption.current = null;

            if (listboxNode) {
                listboxNode.innerHTML = 'No options';
            }
        }

        return optionTemp;
    }, [props.id]);

    const setValue = useCallback((value: string | null) => {
        if (props.id.indexOf('catogoryselection') >= 0 && value !== currentCategories.current && !swithced.current) {
            resetIndex.current = props.id.substring(17);
            props.resetSubFormsHandler(props.id.substring(17));
        }
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        if (value || value === '') {
            filter.current = value;
            setCurrentFormValue(filter.current);
            props.onChangedForm(value, props.currentItem);
            if (props.id.indexOf('catogoryselection') >= 0) {
                currentCategories.current = value;
            }
            currentValue.current = value;
        }
        currentId.current = comboboxNode.id;
        comboboxNode.setSelectionRange(filter.current.length, filter.current.length);
        filterOptions();
    }, [filterOptions, props]);

    const getAllOptions = useCallback(() => {
        const listboxNode = document.getElementById(props.id + '-listbox');
        const nodes = listboxNode?.getElementsByTagName('li');
        let options:HTMLElement[] = [];
        if (nodes && nodes.length > 0) {
            for (let i = 0; i < nodes.length; i++) {
                const node = nodes[i];
                options.push(node);
            }
        }
        if (allOptions.current.length === 0) {
            allOptions.current = options;
        }
    },[props.id]);

    const isOpen = useCallback(() => {
        const listboxNode = document.getElementById(props.id + '-listbox');
        return listboxNode?.style.display === 'block';
    }, [props.id]);

    const isClosed= () => {
        const listboxNode = document.getElementById(props.id + '-listbox');
        return listboxNode?.style.display !== 'block';
    }

    const setCurrentOptionStyle = useCallback((optionEle: HTMLElement | null) => {
        const listboxNode = document.getElementById(props.id + '-listbox');
        for (let i = 0; i < filteredOptions.current.length; i++) {
          let opt = filteredOptions.current[i];
          if (opt === optionEle) {
            opt.setAttribute('aria-selected', 'true');
            if (
              listboxNode && listboxNode.scrollTop + listboxNode.offsetHeight <
              opt.offsetTop + opt.offsetHeight
            ) {
              listboxNode.scrollTop =
                opt.offsetTop + opt.offsetHeight - listboxNode.offsetHeight;
            } else if (listboxNode && listboxNode.scrollTop > opt.offsetTop + 2) {
              listboxNode.scrollTop = opt.offsetTop;
            }
          } else {
            opt.setAttribute('aria-selected', 'false');
          }
        }
    }, [props.id]);

    const setActiveDescendant = useCallback((optionEle: HTMLElement | null) => {
        const comboboxNode = document.getElementById(props.id + '-select');
        if (comboboxNode && optionEle && listboxHasVisualFocus.current) {
            comboboxNode.setAttribute('aria-activedescendant', optionEle.id);
            if (!isOptionInView(optionEle)) {
                optionEle.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        } else if (comboboxNode) {
            comboboxNode.setAttribute('aria-activedescendant', '');
        }
    }, [props.id]);

    const removeVisualFocusAll = useCallback(() => {
        const comboboxNode = document.getElementById(props.id + '-select');
        const listboxNode = document.getElementById(props.id + '-listbox');
        if (comboboxNode) {
            setFocusName('group');
        }
        comboboxHasVisualFocus.current = false;
        listboxHasVisualFocus.current = true;
        if (listboxNode) {
            listboxNode.classList.remove('focus');
        }
        option.current = null;
        setActiveDescendant(null);
    }, [props.id, setActiveDescendant]);

    const close = useCallback((force: boolean, type: string) => {
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        const listboxNode = document.getElementById(props.id + '-listbox');
        const buttonNode = document.getElementById(props.id + '-button');
        if (typeof force !== 'boolean') {
            force = false;
        }

        if (
            comboboxNode && listboxNode && buttonNode
            && (force ||
            (!comboboxHasVisualFocus.current &&
            !listboxHasVisualFocus.current &&
            !hasFormsHover.current))
        ) {
            setCurrentOptionStyle(null);
            listboxNode.style.display = 'none';
            comboboxNode.setAttribute('aria-expanded', 'false');
            buttonNode.setAttribute('aria-expanded', 'false');
            setActiveDescendant(null);
            setFocusName('group focus');
        }
        isOpened.current = false;
        if (enterClicked.current) {
            comboboxNode.focus();
        }
        if (type === 'update' && comboboxNode && (!comboboxNode.value || comboboxNode.value === '')) {
            props.onChangedForm('',  props.currentItem);
        }
        if (type === 'update' && comboboxNode && comboboxNode.value && comboboxNode.value !== '') {
            props.onChangedForm(comboboxNode?.value, props.currentItem);
        }
    }, [props, setActiveDescendant, setCurrentOptionStyle]);

    const switchName = useCallback(() => {
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        if (comboboxNode.id.indexOf('uncdformselection') >=0 && comboboxNode.value && comboboxNode.value !== '') {
            const wisbForms = props.wisbForms(comboboxNode.value);
            if (i18n.resolvedLanguage === 'fr') {
                if (comboboxNode.value.replaceAll('’', '\'') !== wisbForms?.formNameFr.replaceAll('’', '\'') && comboboxNode.value.replaceAll('’', '\'') === wisbForms?.formNameEn.replaceAll('’', '\'') ) {
                    swithced.current = true;
                    setCurrentFormValue(wisbForms?.formNameFr);
                    setValue(wisbForms?.formNameFr);
                }
            } else {
                if (comboboxNode.value.replaceAll('’', '\'') !== wisbForms?.formNameEn.replaceAll('’', '\'') && comboboxNode.value.replaceAll('’', '\'') === wisbForms?.formNameFr.replaceAll('’', '\'') ) {
                    swithced.current = true;
                    setCurrentFormValue(wisbForms?.formNameEn);
                    setValue(wisbForms?.formNameEn);
                }
            }
        }
        if (comboboxNode.id.indexOf('edobformselection') >=0 && comboboxNode.value && comboboxNode.value !== '') {
            const edobForms = props.edobForms(comboboxNode.value);
            if (i18n.resolvedLanguage === 'fr') {
                if (comboboxNode.value.replaceAll('’', '\'') !== edobForms?.formNameFr.replaceAll('’', '\'') && comboboxNode.value.replaceAll('’', '\'') === edobForms?.formNameEn.replaceAll('’', '\'') ) {
                    swithced.current = true;
                    setCurrentFormValue(edobForms?.formNameFr);
                    setValue(edobForms?.formNameFr);
                }
            } else {
                if (comboboxNode.value.replaceAll('’', '\'') !== edobForms?.formNameEn.replaceAll('’', '\'') && comboboxNode.value.replaceAll('’', '\'') === edobForms?.formNameFr.replaceAll('’', '\'') ) {
                    swithced.current = true;
                    setCurrentFormValue(edobForms?.formNameEn);
                    setValue(edobForms?.formNameEn);
                }
            }
        }
        if (comboboxNode.id.indexOf('catogoryselection') >=0 && comboboxNode.value && comboboxNode.value !== '') {
            const categories = props.categories(comboboxNode.value);
            if (i18n.resolvedLanguage === 'fr') {
                if (comboboxNode.value.replaceAll('’', '\'') !== categories?.categoryNameFr.replaceAll('’', '\'') && comboboxNode.value.replaceAll('’', '\'') === categories?.categoryNameEn.replaceAll('’', '\'') ) {
                    swithced.current = true;
                    setCurrentFormValue(categories?.categoryNameFr);
                    setValue(categories?.categoryNameFr);
                }
            } else {
                if (comboboxNode.value.replaceAll('’', '\'') !== categories?.categoryNameEn.replaceAll('’', '\'') && comboboxNode.value.replaceAll('’', '\'') === categories?.categoryNameFr.replaceAll('’', '\'') ) {
                    swithced.current = true;
                    setCurrentFormValue(categories?.categoryNameEn);
                    setValue(categories?.categoryNameEn);
                }
            }
        }
    }, [setValue, i18n.resolvedLanguage, props]);

    useEffect(() => {
        if (i18n.resolvedLanguage !== window.sessionStorage.getItem('lang')) {
            const lang = window.sessionStorage.getItem('lang')!;
            i18n.changeLanguage(lang);
        }
        getAllOptions();
        switchName();
        groupClassName.current = focusName;
        const onBackgroundPointerUpForForms = (event: MouseEvent) => {
            const selectEle = event.target as HTMLElement;
            const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
            if (isOpen() && (selectEle.id.indexOf(props.id + '_option') < 0)) {
                comboboxHasVisualFocus.current = false;
                setCurrentOptionStyle(null);
                removeVisualFocusAll();
                if (!valideteInput(comboboxNode.value.trim()) ) {
                    if (comboboxNode.id === currentId.current) {
                        setCurrentFormValue('');
                        setValue('');
                    }
                    close(true, 'update');
                } else {
                    close(true, 'noupdate');
                }
                setFocusName('group');
            }
        };

        document.body.addEventListener(
            'mouseup',
            e => onBackgroundPointerUpForForms(e),
            true
        );

        return () => {
            document.body.removeEventListener(
                'mouseup',
                e => onBackgroundPointerUpForForms(e),
                true
            );
        }
    },[getAllOptions, props.id, props.resetSubForms, setValue, switchName, focusName, i18n, close, isOpen, removeVisualFocusAll, setCurrentOptionStyle, valideteInput]);
    
    const getLowercaseContent = (node: HTMLElement) => {
        return node?.textContent?.toLowerCase();
    }

    const isOptionInView = (optionEle: HTMLElement) => {
        const bounding = optionEle.getBoundingClientRect();
        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <=
            (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    const setVisualFocusListbox = () => {
        const comboboxNode = document.getElementById(props.id + '-select');
        const listboxNode = document.getElementById(props.id + '-listbox');
        if (comboboxNode) {
            setFocusName('group focus');
        }
        comboboxHasVisualFocus.current = false;
        listboxHasVisualFocus.current = true;
        if (listboxNode) {
            listboxNode.classList.add('focus');
        }
        setActiveDescendant(option.current);
    }

    const setOptionHandler = (optionEle: HTMLElement | null, flag: boolean | null) => {
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        if (typeof flag !== 'boolean') {
            flag = false;
        }

        if (optionEle) {
            option.current = optionEle;
            setCurrentOptionStyle(option.current);
            setActiveDescendant(option.current);

            if (isBoth && comboboxNode && option.current.textContent) {
            //comboboxNode.value = option.current.textContent;
            setCurrentFormValue(option.current.textContent);
            if (flag) {
                comboboxNode.setSelectionRange(
                option.current.textContent.length,
                option.current.textContent.length
                );
            }
                comboboxNode.setSelectionRange(
                    filter.current.length,
                    option.current.textContent.length
                );
            }
        }
    }

    const setVisualFocusCombobox = () => {
        const listboxNode = document.getElementById(props.id + '-listbox');
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        if (listboxNode && comboboxNode) {
            listboxNode.classList.remove('focus');
            setFocusName('group focus');
            comboboxHasVisualFocus.current = true;
            listboxHasVisualFocus.current = false;
            setActiveDescendant(null);
        }
    }

    const getPreviousOption = (currentOption: HTMLElement | null) => {
        if (currentOption !== theFirstOption.current) {
            if (currentOption) {
                const index = filteredOptions.current.indexOf(currentOption);
                return filteredOptions.current[index - 1];
            }
        }
        return theLastOption.current;
    }

    const getNextOption = (currentOption: HTMLElement | null) => {
        if (currentOption !== theLastOption.current) {
            if (currentOption) {
                let index = filteredOptions.current.indexOf(currentOption);
                return filteredOptions.current[index + 1];
            }
        }
        return theFirstOption.current;
    }

    const hasOptions = () => {
        return filteredOptions.current.length;
    }

    const open = () => {
        const comboboxNode = document.getElementById(props.id + '-select');
        const listboxNode = document.getElementById(props.id + '-listbox');
        const buttonNode = document.getElementById(props.id + '-button');
        if (comboboxNode && listboxNode && buttonNode) {
            listboxNode.style.display = 'block';
            comboboxNode.setAttribute('aria-expanded', 'true');
            buttonNode.setAttribute('aria-expanded', 'true');
        }
        isOpened.current = true;
    }

    const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        let flag = false;
        const altKey = event.altKey;

        if (event.ctrlKey || event.shiftKey) {
            return;
        }

        switch (event.code) {
            case 'Enter': {
                if (listboxHasVisualFocus.current) {
                    listClicked.current = true;
                }
                if (option.current?.textContent) {
                    setValue(option.current.textContent);
                }
                close(true, 'update');
                if (!valideteInput(comboboxNode.value.trim())) {
                    if (comboboxNode.id === currentId.current) {
                        // comboboxNode.value = currentValue.current;
                        setCurrentFormValue('');
                        setValue('');
                    }
                }
                setVisualFocusCombobox();
                flag = true;
                break;
            }
            case 'Down':
            case 'ArrowDown': {
                if (filteredOptions.current.length > 0) {
                    if (altKey) {
                        open();
                    } else {
                        open();
                    if (
                        listboxHasVisualFocus.current ||
                        (isBoth && filteredOptions.current.length > 1)
                    ) {
                        setOptionHandler(getNextOption(option.current), true);
                        setVisualFocusListbox();
                    } else {
                        setOptionHandler(theFirstOption.current, true);
                        setVisualFocusListbox();
                    }
                    }
                }
                flag = true;
                break;
            }

            case 'Up':
            case 'ArrowUp': {
                if (hasOptions()) {
                    if (listboxHasVisualFocus.current) {
                        setOptionHandler(getPreviousOption(option.current), true);
                    } else {
                        open();
                        if (!altKey) {
                            setOptionHandler(theLastOption.current, true);
                            setVisualFocusListbox();
                        }
                    }
                }
                flag = true;
                break;
            }
            case 'Esc':
            case 'Escape': {
                if (isOpen()) {
                    close(true, 'update');
                    //setFilter(comboboxNode?.value);
                    filter.current = comboboxNode?.value;
                    filterOptions();
                    setVisualFocusCombobox();
                } else {
                    setValue('');
                    // comboboxNode.value = '';
                    setCurrentFormValue('');
                }
                option.current = null;
                flag = true;
                break;
            }
            case 'Tab': {
                if (listboxHasVisualFocus.current) {
                    if (option.current) {
                        setValue(option.current?.textContent);
                    }
                }
                if (option.current) {
                    setValue(option.current?.textContent);
                }
                close(true, 'update');
                break;
            }
            case 'Home': {
                comboboxNode.setSelectionRange(0, 0);
                flag = true;
                break;
            }
            case 'End': {
                var length = comboboxNode.value.length;
                comboboxNode.setSelectionRange(length, length);
                flag = true;
                break;
            }
            default:
            break;
        }

        if (flag) {
            event.stopPropagation();
            if (event.code === 'Enter' && listClicked.current) {
                enterClicked.current = true;
            } else {
                enterClicked.current = false;
            }
            event.preventDefault();
        }
    };

    const isPrintableCharacter = (str: string) => {
        return str.length === 1 && str.match(/\S| /);
    }

    const keyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        let flag = false;
        let optionTemp = null;
        let char = event.key;

        if (isPrintableCharacter(char)) {
            filter.current = filter.current + char;
        }

        // this is for the case when a selection in the textbox has been deleted
        if (comboboxNode?.value.length < filter.current.length) {
            filter.current = comboboxNode?.value
            option.current = null;
            filterOptions();
        }

        if (event.code === 'Escape' || event.code === 'Esc') {
            return;
        }

        switch (event.code) {
            case 'Backspace': {
                /* setVisualFocusCombobox();
                setCurrentOptionStyle(null);
                // filter.current = currentFormValue!;
                getAllOptions();
                const optionStr = filterOptions();
                if (optionStr && isClosed()) {
                    open();
                }*/
                flag = true;
                break;
            }
            case 'Left':
            case 'ArrowLeft':
            case 'Right':
            case 'ArrowRight':
            case 'Home':
            case 'End': {
                if (isBoth) {
                    filter.current = comboboxNode?.value;
                } else {
                    option.current = null;
                    setCurrentOptionStyle(null);
                }
                setVisualFocusCombobox();
                flag = true;
                break;
            }

            default: {
                if (isPrintableCharacter(char)) {
                    setVisualFocusCombobox();
                    setCurrentOptionStyle(null);
                    flag = true;

                    if (isBoth) {
                        optionTemp = filterOptions();
                        if (isClosed() && comboboxNode?.value.length) {
                            open();
                        }
                    if (optionTemp) {

                        if (
                            getLowercaseContent(optionTemp)?.indexOf(
                                comboboxNode?.value.toLowerCase()
                            ) === 0
                        ) {
                            option.current = optionTemp;
                            if (isBoth || listboxHasVisualFocus.current) {
                                setCurrentOptionStyle(optionTemp);
                                /* if (isBoth) {
                                    setOptionHandler(optionTemp, null);
                                } */
                            }
                        } else {
                            option.current = null;
                            setCurrentOptionStyle(null);
                        }
                    } else {
                        close(false, 'update');
                        option.current = null;
                        setActiveDescendant(null);
                    }
                    } else if (comboboxNode?.value.length) {
                        open();
                    }
                }
                break;
            }
        }

        if (flag) {
            event.stopPropagation();
            event.preventDefault();
        } else {
            if (event.code === 'Enter' && listClicked.current) {
                listClicked.current = false;
            } else if (event.code === 'Enter') {
                event.preventDefault();
            }
        }
    }

    const onComboboxClick = () => {
        if (isOpen()) {
            close(true, 'update');
        } else {
            open();
        }
    }

    const onComboboxBlur = () => {
        removeVisualFocusAll();
    }

    const focusHandler = () => {
        swithced.current = false;
        if (props.id.indexOf('subformselection') >= 0) {
            props.resetSubFormsHandler('');
        }
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        currentValue.current = comboboxNode?.value;
        currentId.current = comboboxNode?.id;
        filter.current = comboboxNode?.value;
        if (allOptions.current.length === 0) {
            getAllOptions();
        }
        filterOptions();
        setVisualFocusCombobox();
        option.current = null;
        setCurrentOptionStyle(null);
        setFocusName('group focus');
    }

    const onButtonClick = () => {
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        if (isOpen()) {
            close(true, 'update');
        } else {
            open();
        }
        comboboxNode.focus();
        setVisualFocusCombobox();
    }

    /* Listbox Events */

    const onListboxPointerover = () => {
        hasFormsHover.current = true;
    }

    const onListboxPointerout = () => {
        if (!isIOS()) {
            hasFormsHover.current = false;
        }
        setTimeout(() => close(false, 'update'), 200);
    }

    // Listbox Option Events

    const onOptionClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        const comboboxNode = document.getElementById(props.id + '-select') as HTMLInputElement;
        setCurrentFormValue(event.currentTarget?.textContent);
        // getAllOptions();

        // props.onChangedForm(event.currentTarget?.textContent!, props.currentItem);
        setValue(event.currentTarget?.textContent);
        
        close(true, 'update');
        setFocusName('group focus');
        comboboxNode.focus();
    }

    const onOptionPointerover = () => {
        hasFormsHover.current = true;
        open();
    }

    const onOptionPointerout = () => {
        if (!isIOS()) {
            hasFormsHover.current = false;
        }
        setTimeout(() => close(false, 'update'), 200);
    }

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentFormValue(event.target.value);
        setValue(event.target.value);
        setVisualFocusCombobox();
        setCurrentOptionStyle(null);
        filter.current = event.target.value;
        const optionStr = filterOptions();
        if (optionStr && isClosed()) {
            open();
        }
    }

    return(
        <div className="form-combobox form-combobox-list">
            <div className={focusName}>
                <input id={props.id + "-select"} className="cb_edit" type="text"
                    autoComplete='off'
                    role="combobox" aria-autocomplete="list" aria-expanded="false"
                    aria-controls={props.id + "-listbox"}
                    placeholder={props.placeholder}
                    onKeyDown={keyDownHandler} onKeyUp={keyUpHandler}
                    onFocus={focusHandler} onClick={onComboboxClick}
                    onBlur={onComboboxBlur}
                    value={currentFormValue!} onChange={e => onChangeHandler(e)} />
                <button type="button" id={props.id + "-button"} aria-label="States" aria-expanded="false" aria-controls={props.id + "-listbox"} tabIndex={-1} onClick={onButtonClick}>
                    <svg className="svg_button" width="14" height="14" aria-hidden="true" focusable="false" >
                        <polygon className="arrow" strokeWidth="0" fillOpacity="0.75" fill="currentcolor" points="3,6 15,6 9,14"></polygon>
                    </svg>
                </button>
            </div>
            { props.type === 'wsib forms' && <ul className='form-list' id={props.id + "-listbox"} role="listbox" aria-label={props.type} onPointerOver={onListboxPointerover} onPointerOut={onListboxPointerout}>
                {
                    props.options.map((option, index) => {
                        const id=`${props.id}_option${index}`;
                        return <li id={id} key={id} role="option" aria-selected="false" onClick={e => onOptionClick(e)} onPointerOver={onOptionPointerover} onPointerOut={onOptionPointerout}>{option}</li>
                    })
                }
            </ul>}
            { props.type === 'edob catogory' && <ul className='form-list' id={props.id + "-listbox"} role="listbox" aria-label={props.type} onPointerOver={onListboxPointerover} onPointerOut={onListboxPointerout}>
                {
                    props.options.map((option, index) => {
                        const id=`${props.id}_option${index}`;
                        return <li id={id} key={id} role="option" aria-selected="false" onClick={e => onOptionClick(e)} onPointerOver={onOptionPointerover} onPointerOut={onOptionPointerout}>{option}</li>
                    })
                }
            </ul>}
        </div>
    );
}

export default FormDropdownList;
