import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { UncdContext } from "./StateProvider"
import { useMsal } from "@azure/msal-react";

const Print: React.FC = (props) => {
    const { instance } = useMsal();

    const { t, i18n } = useTranslation();
    const ctx = useContext(UncdContext);

    const getEmailAddress = () => {
        const account = instance.getActiveAccount();
        return account?.username;
    }

    return (
        <Container id="section-to-print">
            <Grid container rowSpacing={1}>

                <Grid item xs={12} className="row">
                    {ctx.isUncd && <div><Typography variant="h2">{t("print.head")}</Typography></div>}
                    {!ctx.isUncd && <div><Typography variant="h2">{t("edob.print.head")}</Typography></div>}
                    {!ctx.isUncd && <div><Typography variant="h2"  style={{marginTop: 20}}>{t("label.claim.number")}{ctx.claimnumberClaimant}</Typography></div>}
                    <br role="presentation"/>
                </Grid>
                <Grid item xs={12} className="row">
                    <span style={{ marginRight: '20px' }}>{t("submission.time")}</span>{ctx.uploadSubmitTime?.toLocaleString()}
                    <br role="presentation"/><br role="presentation"/>
                    <span style={{ marginRight: '20px' }}>{t("print.documentation.submit")}</span>
                    <br role="presentation"/><br role="presentation"/>
                    <span style={{ marginRight: '20px' }}>{t("print.documentation.submit.details")}</span>
                </Grid>
                {ctx.isUncd && <Grid item xs={12} className="row">
                    <div style={{ marginTop: '20px' }}><Typography variant="h3">{t('claimant.details')}</Typography></div>
                </Grid>}
                {ctx.isUncd && <Grid item xs={12}>
                    <Paper style={{ width: '95%', padding: 2, }} elevation={0}>
                        <Grid container style={{ margin: '10px' }} rowSpacing={1}>
                            <Grid item xs={12} >
                                <span style={{ marginRight: '20px' }}>{t("label.claimant.firstname")}</span>{ctx.firstnameClaimant.trim()}
                            </Grid>
                            <Grid item xs={12} >
                                <span style={{ marginRight: '20px' }}>{t("label.claimant.lastname")}</span>{ctx.lastnameClaimant.trim()}
                            </Grid>
                            <Grid item xs={12} >
                                {i18n.resolvedLanguage !== 'fr' && <React.Fragment>
                                    <span style={{ marginRight: '20px' }}>{t("label.claimant.dateofbirth.review")}</span>{new Date(parseInt(ctx.seletedBirthYear), parseInt(ctx.seletedBirthMonthNumStr) - 1, parseInt(ctx.seletedBirthDate)).toLocaleDateString('en',
                                        { year: 'numeric', month: 'long', day: 'numeric' })}
                                </React.Fragment>}
                                {i18n.resolvedLanguage === 'fr' && <React.Fragment>
                                    <span style={{ marginRight: '20px' }}>{t("label.claimant.dateofbirth.review")}</span>{new Date(parseInt(ctx.seletedBirthYear), parseInt(ctx.seletedBirthMonthNumStr) - 1, parseInt(ctx.seletedBirthDate)).toLocaleDateString('fr',
                                        { year: 'numeric', month: 'long', day: 'numeric' })}
                                </React.Fragment>}
                            </Grid>
                            <Grid item xs={12} >
                                {ctx.accidentDateClaimant && i18n.resolvedLanguage !== 'fr' && <React.Fragment>
                                    <span style={{ marginRight: '20px' }}>{t("label.claimant.dateofaccident.review")}</span>{new Date(parseInt(ctx.seletedAccidentYear), parseInt(ctx.seletedAccidentMonthNumStr) - 1, parseInt(ctx.seletedAccidentDate)).toLocaleDateString('en',
                                        { year: 'numeric', month: 'long', day: 'numeric' })}
                                </React.Fragment>}
                                {ctx.accidentDateClaimant&& i18n.resolvedLanguage === 'fr' && <React.Fragment>
                                    <span style={{ marginRight: '20px' }}>{t("label.claimant.dateofaccident.review")}</span>{new Date(parseInt(ctx.seletedAccidentYear), parseInt(ctx.seletedAccidentMonthNumStr) - 1, parseInt(ctx.seletedAccidentDate)).toLocaleDateString('fr',
                                        { year: 'numeric', month: 'long', day: 'numeric' })}
                                </React.Fragment>}
                            </Grid>

                        </Grid>
                    </Paper>
                </Grid>}

                <Grid item xs={12} className="row">
                    <br role="presentation"/>
                    {ctx.isUncd && <div><Typography variant="h3">{t("reports")}</Typography></div>}
                </Grid>
                {ctx.isUncd && <Grid item xs={12}>
                    <Paper variant="outlined" style={{ width: '95%', padding: 2, backgroundColor: '#f5f5f5' }} elevation={0}>
                        <Grid container style={{ margin: '10px' }} rowSpacing={1}>
                            <Grid item xs={12} >
                                <span style={{ marginRight: '20px' }}>{t("label.file")}</span><span style={{ marginRight: '20px' }}>{ctx.documentUploadQueue[0]._file.name}</span>
                                {ctx.documentUploadQueue[0]._file.size / 1024 / 1024 >= 1 && <label style={{ marginLeft: 20 }}>{Math.round(ctx.documentUploadQueue[0]._file.size / 1024 / 1024)} {t("file_size_mb")}</label>}
                                {ctx.documentUploadQueue[0]?._file?.size / 1024 / 1024 < 1 && <label style={{ marginLeft: 20 }}>{Math.round(ctx.documentUploadQueue[0]._file?.size / 1024)} {t('file_size_kb')}</label>}
                            </Grid>

                            <Grid item xs={12} >
                                <span style={{ marginRight: '20px' }}>{t("label.document-type")}</span>
                                {i18n.resolvedLanguage === 'fr' ? <span >{ctx.documentUploadQueue[0].wsibForm?.formNameFr}</span> : <span>{ctx.documentUploadQueue[0].wsibForm?.formNameEn}</span>}
                            </Grid>

                            <Grid item xs={12} >
                                <span style={{ marginRight: '20px' }}>{t('message_has_support_file')}</span>
                                {ctx.hasSupportDoc === 'yes' ? <span>{t('message_dob_documentation_docfromYes')}</span> :
                                    <span >{t('message_dob_documentation_docfromNo')}</span>}
                            </Grid>

                            {ctx.hasSupportDoc === 'yes' && <Grid item xs={12} >
                                <br role="presentation"/>
                                <div><Typography variant="h3">{t("suppporting.documents")}</Typography></div>
                            </Grid>}

                            {ctx.supportDocumentUploadQueue.map((supportDoc, index) => {
                                return <Grid item xs={12} key={"supportfile" + index.toString()}>
                                    <Paper variant="outlined" style={{ width: '95%', padding: 3, backgroundColor: '#f5f5f5' }} elevation={0}>
                                        <Grid container style={{ margin: '10px' }} rowSpacing={1}>
                                            <Grid item xs={12} >
                                                <span style={{ marginRight: '20px' }}>{t("label.support.file")}</span><span style={{ marginRight: '20px' }}>{supportDoc._file.name}</span>
                                                {supportDoc._file.size / 1024 / 1024 >= 1 && <label style={{ marginLeft: 20 }}>{Math.round(supportDoc._file.size / 1024 / 1024)} {t("file_size_mb")}</label>}
                                                {ctx.documentUploadQueue[0]?._file?.size / 1024 / 1024 < 1 && <label style={{ marginLeft: 20 }}>{Math.round(ctx.documentUploadQueue[0]._file?.size / 1024)} {t('file_size_kb')}</label>}
                                            </Grid>

                                            <Grid item xs={12} >
                                                <span style={{ marginRight: '20px' }}>{t("label.document-type")}</span>
                                                {i18n.resolvedLanguage === 'fr' ? <span >{supportDoc.wsibForm?.formNameFr}</span> : <span>{supportDoc.wsibForm?.formNameEn}</span>}
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            })}
                        </Grid>
                    </Paper>
                </Grid>}
                {!ctx.isUncd && <Grid item xs={12}>
                    {ctx.documentUploadQueue.map((item, index) => {
                                if (item.isWsibForm === 'yes') {
                                    return (
                                        <Paper variant="outlined" key={index} style={{ width: '95%', padding: 2, backgroundColor: '#f5f5f5', marginBottom: '10px' }} elevation={0}>
                                            <Grid container style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }} rowSpacing={1}>
                                                <Grid item xs={12} >
                                                    <p><strong>{t("lable.form.name")}</strong></p>
                                                    {i18n.resolvedLanguage === 'fr' ? <span >{item.edobForm?.formNameFr}</span> : <span>{item.edobForm?.formNameEn}</span>}
                                                </Grid> 
                                                <Grid item xs={12} >
                                                    <p><strong>{t("label.file")}</strong></p><span style={{ marginRight: '20px' }}>{item._file.name}</span>|
                                                    {item._file.size / 1024 / 1024 >= 1 && <label style={{ marginLeft: 20 }}>{Math.round(item._file.size / 1024 / 1024)} {t("file_size_mb")}</label>}
                                                    {item?._file?.size / 1024 / 1024 < 1 && <label style={{ marginLeft: 20 }}>{Math.round(item._file?.size / 1024)} {t('file_size_kb')}</label>}
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    )
                                } else{
                                    return (
                                        <Paper variant="outlined" key={index} style={{ width: '95%', padding: 2, backgroundColor: '#f5f5f5', marginBottom: '10px'}} elevation={0}>
                                            <Grid container style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }} rowSpacing={1}>
                                                <Grid item xs={12} >
                                                    <p><strong>{t("lable.form.name")}</strong></p>
                                                    {i18n.resolvedLanguage === 'fr' ? <span >{item.subEdobForm?.formNameFr}</span> : <span>{item.subEdobForm?.formNameEn}</span>}
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <p><strong>{t("label.file")}</strong></p><span style={{ marginRight: '20px' }}>{item._file.name}</span>|
                                                    {item._file.size / 1024 / 1024 >= 1 && <label style={{ marginLeft: 20 }}>{Math.round(item._file.size / 1024 / 1024)} {t("file_size_mb")}</label>}
                                                    {item?._file?.size / 1024 / 1024 < 1 && <label style={{ marginLeft: 20 }}>{Math.round(item._file?.size / 1024)} {t('file_size_kb')}</label>}
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    )
                                }
                            })}
                </Grid>}
                {ctx.isUncd && <Grid item xs={12} className="row">
                    <br role="presentation"/><br role="presentation"/>
                    <span style={{ marginRight: '20px' }}>{t("submitted.by")}</span>{getEmailAddress()}

                </Grid>}
                {ctx.isUncd && <Grid item xs={12} className="row">
                    <span style={{ marginRight: '20px' }}>{t("submission.time")}</span>{ctx.uploadSubmitTime?.toLocaleString()}
                </Grid>}

                {!ctx.isUncd && <Grid item xs={12} className="row" style={{marginTop: '40px'}}>
                    <p style={{fontFamily: 'Arial', fontWeight: 400}}>{t("label.footer.wsib")} {t("label.footer.address")} | {t("label.footer.city")} {t("label.footer.country")}
                    <br role="presentation"/>
                    {t("label.footer.phone")} | {t("label.footer.fax")} | <span style={{fontFamily: 'Arial', color: '#0076BF'}}><a href={t("label.footer.link")}>{t("label.footer.link.text")}</a></span></p>
                </Grid>}
            </Grid>
        </Container>
    );
}

export default Print;